<template>
   <form class="cursor-pointer relative w-full">
      <div
         class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none"
      >
         <ChevronDownIcon
            v-if="disabled"
            class="text-gray-1000 h-5 w-5"
         ></ChevronDownIcon>
      </div>
      <input
         v-model="currentTime"
         type="time"
         id="time"
         list="popularHours"
         :disabled="disabled"
         :class="disabled ? 'cursor-auto' : 'cursor-pointer'"
         class="ring-2 w-full text-xs sm:text-sm font-medium focus:ring-2 focus:ring-primary-300 leading-none ring-primary-300 p-2 text-black rounded"
         min="09:00"
         max="18:00:00"
         required
      />
   </form>
</template>
<script setup lang="ts">
   import { ChevronDownIcon } from '@heroicons/vue/24/outline';
   const props = defineProps({
      time: {
         type: String,
         required: false,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
   });
   const currentTime = defineModel();
</script>
