<template>
   <div class="flex flex-wrap gap-1 sm:gap-3 items-center justify-between">
      <div class="flex-1">
         <CommonTimePicker
            v-model="start"
            :disabled="disabled"
         ></CommonTimePicker>
      </div>

      <span v-if="!hideEnd" class="text-sm">to</span>
      <div class="flex-1">
         <CommonTimePicker
            v-show="!hideEnd"
            v-model="end"
            :disabled="disabled || disableEnd"
         ></CommonTimePicker>
      </div>

      <BaseButton
         :prepend-icon="XMarkIcon"
         v-if="!hideAction"
         variant="plain"
         color="black"
         size="sm"
         @click.prevent="emit('remove')"
         style="padding: 0px"
      />
      <BaseButton
         v-if="!hideAction"
         size="sm"
         variant="plain"
         color="black"
         @click.prevent="emit('add')"
         style="padding: 0px"
      >
         <PlusCircleIcon class="w-5 h-5" />
      </BaseButton>
   </div>
</template>
<script setup lang="ts">
   import { PlusCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
   const props = defineProps({
      hideEnd: {
         type: Boolean,
         default: false,
      },
      disabled: {
         type: Boolean,
         required: false,
      },
      lastEndTime: {
         type: String,
         default: '',
      },
      fromSchedule: {
         type: Boolean,
         default: false,
      },
      date: {
         type: String,
         default: '',
      },
      hideAction: {
         type: Boolean,
         default: false,
      },
      showClose: {
         type: Boolean,
         default: true,
      },
      disableEnd: {
         type: Boolean,
         default: false,
      },
   });
   const start = defineModel('start', {
      required: true,
   });
   const end = defineModel('end', {
      required: true,
   });
   const emit = defineEmits(['start-time', 'end-time', 'remove', 'add']);

   watch(
      () => start.value,
      () => {
         emit('start-time', start.value);
      }
   );
</script>
